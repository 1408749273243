import { atom } from 'recoil';

import { localStorageEffect } from '@utils/Common';

export const loadingState = atom<boolean>({
  key: 'loadingState',
  default: false,
});

export const rouletteEventModalState = atom<boolean>({
  key: 'rouletteEventModalState',
  default: false,
});
export const testFininshedState = atom<boolean>({
  key: 'testFininshedState',
  default: false,
});
export const reservationFinishedState = atom<boolean>({
  key: 'reservationFinished',
  default: false,
  effects: [localStorageEffect('reservationFinished')],
});

export const globalVisibleState = atom<{
  [key: string]: {
    maintainDate: Date | null;
    hideDate: Date | null;
  };
}>({
  key: 'globalVisible',
  default: {},
  effects: [localStorageEffect('globalVisible')],
});
