import { AppDownloadBottomSheet } from '@components/AppDownloadBottomSheet';

export const WebToAppBottomSheet = ({
  close,
  onTodayNoShowClick,
}: {
  close: () => void;
  onTodayNoShowClick: () => void;
}) => {
  return (
    <AppDownloadBottomSheet
      onTodayNoShowClick={onTodayNoShowClick}
      isOpen={true}
      close={close}
      position='home_initial_modal'
    />
  );
};
