import { useRecoilValue } from 'recoil';

import { loadingState } from '@recoilState';

import { Loading } from '@components/Loading';

export const GlobalLoading = () => {
  const loading = useRecoilValue(loadingState);

  return <>{loading && <Loading />}</>;
};
