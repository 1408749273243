import { atom, selectorFamily } from 'recoil';

type RouterState = {
  isReady: boolean;
  lat: string;
  lng: string;
};

type TargetLocationParams = {
  routerState: RouterState;
  myLocation: {
    latitude: number;
    longitude: number;
  } | null;
};

export const locationState = atom<LocationType | null>({
  key: 'locationState',
  default: null,
});

export const mapLocationState = atom<LocationType | null>({
  key: 'mapLocationState',
  default: null,
});

export const targetLocationSelectorFamily = selectorFamily<
  LocationType | null,
  TargetLocationParams
>({
  key: 'targetLocationSelectorFamily',
  get:
    (params) =>
    ({ get }) => {
      const { routerState, myLocation } = params;
      const mapLocation = get(mapLocationState);

      const { isReady, lat, lng } = routerState;

      const isCoordExist = lat.length && lng.length;

      return !isReady || !myLocation
        ? null
        : mapLocation !== null
        ? mapLocation
        : isCoordExist
        ? { latitude: Number(lat), longitude: Number(lng) }
        : myLocation;
    },
});

export const locationPermissionState = atom<LocationPermission>({
  key: 'locationPermissionState',
  default: '',
});

export const isShowingNoPermissionState = atom<boolean>({
  key: 'isShowingNoPermissionState',
  default: true,
});

export const mapZoomState = atom<number>({
  key: 'mapZoomState',
  default: 11,
});
