export const getBadgeStyles = (state: string) => {
  switch (state) {
    case 'Request':
    case 'Arrival':
    case 'Appointed':
      return 'filled-green';
    case 'RefundRequested':
      return 'filled-red';
    case 'Canceled':
    case 'Refunded':
    default:
      return 'filled-gray';
  }
};

export const sortOrders = (orders: Order[]) =>
  orders.sort((a, b) => {
    if (a.requestedAt === null) return 1;
    if (b.requestedAt === null) return -1;

    return b.requestedAt.getTime() - a.requestedAt.getTime();
  });

export const 방문연수학원 = {
  z5NDkv4bqsOVF5i0KEHy: {
    academyName: '하예서부',
    address: '경기 파주시 파주읍 학당말길 141',
    image: '/training/하예서부.png',
  },
  homCBU2h2Yg7p6berPax: {
    academyName: '용인',
    address: '경기 용인시 처인구 포곡읍 석성로 925',
    image: '/training/용인.png',
  },
  '1yyMKNljZeSCdsTbBp2i': {
    academyName: '뉴강남',
    address: '경기 가평군 설악면 신천중앙로28번길 3-136',
    image: '/training/뉴강남.png',
  },
  ...(process.env.NEXT_PUBLIC_PLATFORM_ENV !== 'production'
    ? {
        lg1r3LDGLSnwwT5pJtIQ: {
          academyName: '테스트3',
          address: '서울특별시 중구 을지로 50',
          image: '/training/용인.png',
        },
      }
    : {}),
};
