import { AtomEffect } from 'recoil';

import SuperJSON from 'superjson';

const store = typeof window !== 'undefined' ? window.localStorage : null;

export const localStorageEffect: (key: string) => AtomEffect<any> =
  (key) =>
  ({ setSelf, onSet }) => {
    if (store) {
      const savedValue = store.getItem(key);
      if (savedValue != null) {
        setSelf(SuperJSON.parse(savedValue) ?? JSON.parse(savedValue) ?? savedValue);
      }

      onSet((newValue, _, isReset) => {
        isReset ? store.removeItem(key) : store.setItem(key, SuperJSON.stringify(newValue));
      });
    }
  };

export const familyLocalStorageEffect: (key: string, id: string) => AtomEffect<any> =
  (key, id) =>
  ({ setSelf, onSet }) => {
    if (store) {
      const savedValue = store.getItem(key);
      if (savedValue != null) {
        setSelf(
          id in SuperJSON.parse<{ id: string }>(savedValue)
            ? SuperJSON.parse<{ [key: string]: unknown }>(savedValue)[id]
            : { maintainDate: null, hideDate: null },
        );
      }

      onSet((newValue, oldValue, isReset) => {
        const savedValue = store.getItem(key);
        isReset
          ? store.removeItem(key)
          : store.setItem(
              key,
              SuperJSON.stringify({
                ...(savedValue ? SuperJSON.parse(savedValue) : {}),
                [id]: newValue,
              }),
            );
      });
    }
  };
