import { useGetUser } from 'database';
import { BOXBUTTON_COLOR, BoxButton, Icon, Portal } from 'design-system';
import Image from 'next/image';
import { useRouter } from 'next/router';

import { useWindowSize } from '@hooks';

export const RouletteModal = ({ close }: { close: () => void }) => {
  const { user } = useGetUser();
  const { width } = useWindowSize();
  const router = useRouter();

  const onClickRoute = () => {
    if (!user) return;
    // 고등학생 이벤트 참여 유저라면
    const isHighSchoolStudent = user.coupons.find((coupon) => coupon.isHighschoolStudent);
    if (isHighSchoolStudent) {
      router.push('/event/roulette/23FW-High?isDirectApproach=true');
    }
    // 대학교 이벤트 로직 추가 예정
    else {
      router.push('/event/roulette/23FW-Invite?isDirectApproach=true');
    }
    close();
  };

  return (
    <>
      <Portal>
        <div
          className='absolute-center'
          style={{
            width: width > 500 ? 430 : width - 70,
          }}>
          <Icon
            icon='close'
            color='gray-600'
            size={24}
            className='absolute right-20 top-20 z-10'
            onClick={(e) => {
              close();
              e.preventDefault();
            }}
          />
          <div className='relative'>
            <Image
              width={918}
              height={1203}
              src='/events/roulette/23FW-Invite-modal.png'
              alt='할인 가능'
            />
            <div className='absolute bottom-24 w-full px-24'>
              <BoxButton
                text='자세히 보기'
                color={BOXBUTTON_COLOR.DTYellow}
                onClick={onClickRoute}
              />
            </div>
          </div>
        </div>
      </Portal>
    </>
  );
};
