import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { rouletteEventModalState } from '@recoilState';
import { useGetEventRoom, useGetUser } from 'database';

import { useHandleGlobalVisible } from '@hooks/use-handle-global-visible';
import { isAppApproaching } from '@utils/Common';

import { ReviewRequestModal, TogetherReadyModal } from './components';
import { WebToAppBottomSheet } from './components/WebToAppBottomSheet';

// 347

const ONE_DAY = 1000 * 60 * 60 * 24;

const isAppApproach = isAppApproaching();

const TOMORROW = new Date(new Date().getTime() + ONE_DAY);

export const GlobalModal = () => {
  const { user, isLoading: isUserLoading } = useGetUser();
  const { data: eventRoom, isFetching: isEventRoomLoading } = useGetEventRoom(
    user?.togetherEvent?.eventRoom.id,
  );
  const isRecoilRouletteEventModalVisible = useRecoilValue(rouletteEventModalState);

  const keyConditions: KeyCondition[] = useMemo(
    () => [
      {
        key: 'reviewRequest',
        conditions: [Boolean(user && user.canWriteReview)],
        expiredOption: {
          showAfterExpired: false,
        },
      },
      {
        key: 'togetherReady',
        conditions: [Boolean(user && eventRoom && eventRoom.isAnyMemberReady(user.uid))],
        expiredOption: {
          showAfterExpired: false,
        },
      },
      {
        key: 'webToApp',
        conditions: [!isAppApproach],
        availablePaths: ['/'],
      },
    ],
    [user, eventRoom, isRecoilRouletteEventModalVisible],
  );

  const {
    visibleStates: [isReviewRequestVisible, isTogetherReadyVisible, isWebToAppVisible],
    close,
    isVisible,
    savedState,
    setSavedStateWithKey,
  } = useHandleGlobalVisible({
    keyConditions,
    isLoading: isUserLoading || isEventRoomLoading,
  });

  if (isUserLoading || isEventRoomLoading || !isVisible) return null;

  return (
    <>
      {isReviewRequestVisible ? (
        <ReviewRequestModal close={close} />
      ) : isTogetherReadyVisible ? (
        <TogetherReadyModal close={close} />
      ) : isWebToAppVisible ? (
        <WebToAppBottomSheet
          close={close}
          onTodayNoShowClick={() => {
            close();
            setSavedStateWithKey('webToApp', { hideDate: TOMORROW });
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};
