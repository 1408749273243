import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

// import { LogEvent } from '@analytics/event/GAEvent';
import { isScriptReadyState, isWebviewReadyState } from '@recoilState';
import { useGetUser } from 'database';
import Script from 'next/script';

import { FacebookSDK, MSClarity, Maze } from './scripts';

// signin 이벤트는 런타임에서 한번만 찍어야 하기 때문에
// 체크하는 변수를 정의함.
let isSigninChecked = false;

const useLogUser = () => {
  const { user } = useGetUser();

  useEffect(() => {
    if (!user || isSigninChecked) return;

    isSigninChecked = true;
    // LogEvent.유입.로그인({
    //   userID: user.token,
    //   userEmail: user.email,
    //   userPhone: user.phoneNum,
    //   attributes: {
    //     userName: user.name,
    //     userId: user.token,
    //     userEmail: user.email,
    //     userPhone: user.phoneNum,
    //     provider: user.provider,
    //   },
    // });
  }, [user]);
};

export const Config = () => {
  /**
   * 웹뷰가 준비되었는지 여부
   */

  const [isWebviewReady, setIsWebviewReady] = useRecoilState(isWebviewReadyState);
  const [isScriptReady, setIsScriptReady] = useRecoilState(isScriptReadyState);

  useLogUser();
  // debugger;

  useEffect(() => {
    setTimeout(() => {
      setIsWebviewReady(true);
    }, 100);

    window.addEventListener('flutterInAppWebViewPlatformReady', function () {
      setIsWebviewReady(true);
    });

    return () => {
      window.removeEventListener('flutterInAppWebViewPlatformReady', function () {
        setIsWebviewReady(true);
      });
    };
  }, []);

  useEffect(() => {
    if (window.flutter_inappwebview && isWebviewReady && isScriptReady) {
      window.flutter_inappwebview.callHandler('SendRemoteConfig');
    }
  }, [isWebviewReady, isScriptReady]);

  useEffect(() => {
    if (window.Kakao && window.Kakao.isInitialized()) return;
    window.Kakao.init(process.env.NEXT_PUBLIC_KAKAO_LOGIN_JAVASCRIPT_API_KEY);
  }, []);

  return (
    <>
      <Script
        strategy='afterInteractive'
        onLoad={() => {
          setIsScriptReady(true);
        }}
        src='/app-script.js'
      />
      {/* <Script strategy='beforeInteractive' src='/amplitude-script.js' /> */}
      {/* <ADSense /> */}
      <MSClarity />
      <FacebookSDK />
      <Maze />
    </>
  );
};
