import { useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { reservationFinishedState, testFininshedState } from '@recoilState';

import { useHandleGlobalVisible } from '@hooks/use-handle-global-visible';
import { isAppApproaching } from '@utils/Common';

const requestInAppReview = async () => {
  if (await window.flutter_inappwebview.callHandler('checkInAppReviewAvailable')) {
    // requestReview의 비동기 동작은 열리는 것에만 비동기이고, 닫히는 것에는 비동기가 아리라는 점에 유의
    await window.flutter_inappwebview.callHandler('requestReview');
  }
};

const isAppApprach = isAppApproaching();

export const GlobalReview = () => {
  const isTestFininshedState = useRecoilValue(testFininshedState);
  const isReservationFinishedState = useRecoilValue(reservationFinishedState);

  const keyConditions: KeyCondition[] = useMemo(
    () =>
      [
        {
          key: 'testFininshed',
          conditions: [isAppApprach, isTestFininshedState],
          expiredOption: {
            showAfterExpired: false,
          },
        },
        {
          key: 'reservationFinished',
          conditions: [isAppApprach, isReservationFinishedState],
          expiredOption: {
            showAfterExpired: false,
          },
          availablePaths: ['/'],
        },
      ] as KeyCondition[],
    [isTestFininshedState, isReservationFinishedState],
  );

  const {
    visibleStates: [isTestFininshed, isReservationFinished],
    close,
  } = useHandleGlobalVisible({
    keyConditions,
  });

  useEffect(() => {
    if (isTestFininshed || isReservationFinished) {
      requestInAppReview();
      // close를 그냥 선언함. close는 in-app-review를 닫는 동작이 아니고, flag를 off 해주는 의미임.
      close();
    }
  }, [isTestFininshed, isReservationFinished]);

  return <></>;
};
