import { BaseLessonCode } from './base-lesson-code';

export class Lesson extends BaseLessonCode {
  price: number;
  isPopular?: boolean;
  arrivalMethod: ArrivalMethod;
  isPriceInquiry: boolean;
  //  수업료에서 검정료 등을 빼서 받는 학원들이 있어 할인금을 표시하기 위한 가격. 실제 결제 금액은 price여야 하고, 보여지는 가격은 additionalPrice를 더해서 표시한다.
  additionalPrice: number;
  // 수업료에서 할인금을 빼서 받는 학원들이 있어 할인금을 표시하기 위한 가격. 실제 결제 금액은 price여야 하고, 보여지는 가격은 discountPrice를 더해서 표시한다.
  discountPrice: number;
  name: string;
  description: string;

  constructor({
    code,
    price,
    isPopular,
    arrivalMethod,
    isPriceInquiry,
    description,
    name,
    additionalPrice,
    discountPrice,
  }: LessonData) {
    super(code);
    this.price = price;
    this.isPopular = isPopular;
    this.arrivalMethod = arrivalMethod;
    this.isPriceInquiry = isPriceInquiry;
    this.description = description ?? '';
    this.name = name ?? '';
    this.additionalPrice = additionalPrice ?? 0;
    this.discountPrice = discountPrice ?? 0;
  }

  get isTraining() {
    return this.code.includes('TRAINING');
  }

  get totalPrice() {
    return this.price + this.additionalPrice + this.discountPrice;
  }

  get renderPrice() {
    if (this.isPriceInquiry) {
      return '가격 문의';
    }
    return `${(this.price + this.additionalPrice + this.discountPrice).toLocaleString()}원`;
  }

  public togglePriceInquiry() {
    this.isPriceInquiry = !this.isPriceInquiry;
  }

  public get() {
    return super.get();
  }

  // application에서 사용되는 넘기는데 예약시에 데이터
  get lessonInfo(): LessonInfo {
    return {
      lessonCode: this.code,
      lessonName: this.label,
      lessonPrice: this.price,
      lessonDiscountPrice: this.discountPrice,
      lessonTotalPrice: this.totalPrice,
      lessonAdditionalPrice: this.additionalPrice,
      arrivalMethod: this.arrivalMethod,
      lessonPeriodText: '',
      lessonRegistType: '',
    };
  }
}
