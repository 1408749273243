import { atom } from 'recoil';

import { localStorageEffect } from '@utils/Common';

export const FilterDefaultValue: AcademyFilters = {
  lesson: { value: 'TWO_NORMAL', label: '2종 보통' },
  reservationRange: { value: null, label: '' },
  sortOrder: { value: '가까운 순', label: '가까운 순' },
  shuttle: { value: [], label: '' },
  isOnlyBookable: { value: true, label: '' },
  additionalProperties: { value: [], label: '' },
};

export const filtersState = atom<AcademyFilters>({
  key: 'filtersState',
  default: FilterDefaultValue,
});

export const searchedAcademyState = atom<boolean>({
  key: 'searchedAcademyState',
  default: false,
});

export const recentSearchState = atom<SearchAcademyData[] | SearchAddressData[]>({
  key: 'recentSearchState',
  default: [],
  effects: [localStorageEffect('recentSearch')],
});

export const simulationLaunchCoachMarkAtom = atom<{
  onExplore: boolean;
  onAcademy: string[];
  onEvent: boolean;
}>({
  key: 'simulationLaunchCoachMark',
  default: {
    onExplore: true,
    onAcademy: [],
    onEvent: true,
  },
  effects: [localStorageEffect('simulationLaunchCoachMark')],
});
