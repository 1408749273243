import { useCallback, useEffect } from 'react';

import { useGetEventRoom, useGetUser } from 'database';
import { BOXBUTTON_COLOR, BoxButton, NewIcon } from 'design-system';
import Image from 'next/image';
import { useRouter } from 'next/router';

import { Modal } from '@components';

export const TogetherReadyModal = ({ close }: { close: () => void }) => {
  const { user } = useGetUser();
  const { data: eventRoom } = useGetEventRoom(user?.togetherEvent?.eventRoom.id);
  const router = useRouter();

  useEffect(() => {
    if (!eventRoom || !user) return;
    eventRoom.setAlerted(user.uid);
  }, []);

  const goToEventRoom = useCallback(async () => {
    if (!user) return;
    const { togetherEvent } = user;
    if (!togetherEvent) return;
    const { eventRoom } = togetherEvent;
    await router.push(`/event/together/room/${eventRoom.id}`);
  }, [user, router]);

  return (
    <>
      <Modal type="big" onClickBackground={close}>
        <div className="absolute right-16 top-16 cursor-pointer" onClick={close}>
          <NewIcon icon="x-outlined" size={20} className="fill-new-gray-600" />
        </div>
        <div className="flex flex-col items-center">
          <Image width={100} height={100} src="/events/money.png" alt="할인 가능" />
          <div className="text-Title2">할인받을 수 있어요.</div>
          <div className="text-Body5 mt-5">친구가 수업을 다 선택해서 할인을 받을 수 있어요.</div>
          <div className="mt-21 w-[224px]">
            <BoxButton text="팀 보러가기" color={BOXBUTTON_COLOR.BLACK} onClick={goToEventRoom} />
          </div>
        </div>
      </Modal>
    </>
  );
};
