import { db } from 'firebase-config';
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import { findMethodAndReplace } from 'shared-values';

import { Order } from '../models';

export const getRefundedOrders = async ({ queryClient, queryKey }: QueryFn) => {
  const [, academyId] = queryKey as [string, string];
  const q = query(
    collection(db, 'Order'),
    where('receipt.academyId', '==', academyId),
    where('state', 'in', ['Refunded', 'RefundRequested']),
    orderBy('selectedTime.firstVisit.date'),
  );

  const result: Order[] = [];

  const querySnapshot = await getDocs(q);

  for (const doc of querySnapshot.docs) {
    const docData = { id: doc.id, ...doc.data() } as OrderData;

    // timestamp를 date로 변환
    findMethodAndReplace(docData, 'toDate');
    result.push(new Order({ ...docData, queryClient }));
  }

  return result;
};
