export const 운전선생허용수업: LessonCode[] = [
  'ONE_NORMAL',
  'TWO_NORMAL',
  'ONE_CANCEL',
  'TWO_CANCEL',
  'ONE_NORMAL_ROAD',
  'TWO_NORMAL_ROAD',
  'ONE_LARGE',
  'TRANSFORM_TWO_TO_ONE',
  'TWO_SMALL_NEW',
  'TWO_SMALL_HAVE_LICENSE',
  'TWO_SMALL_HAVE_MOTOR',
  'TRAINING_4_HOURS',
  'TRAINING_6_HOURS',
  'TRAINING_10_HOURS',
  'TWO_NORMAL_VISIT_TRAINING_8_HOURS',
];
