import { useEffect, useMemo } from 'react';

import { sessionId } from '@analytics/LogEvent/BaseLogEvent';
import { useGetUser } from '@db/collections';
import { useQuery } from '@tanstack/react-query';
import { useGetEventRoom } from 'database';
import { db } from 'firebase-config';
import { collection, getDocs, limit, orderBy, query, where } from 'firebase/firestore';
import { AnimatePresence, motion } from 'framer-motion';
import { isEmpty } from 'lodash-es';
import { useRouter } from 'next/router';
import { cn } from 'tailwind-config';

import { WEB_QUERY_KEY } from '@constants';
import { useHandleGlobalVisible } from '@hooks/use-handle-global-visible';
import { isAppApproaching } from '@utils/Common';

import { TogetherRoomBottomFloatingBar } from './components';
import { ReservationReturnBanner } from './components/ReservationReturnBanner';

const isAppApproach = isAppApproaching();

const ONE_DAY = 1000 * 60 * 60 * 24;

const TOMORROW = new Date(new Date().getTime() + ONE_DAY);

export const GlobalBottomFloatingBar = () => {
  const { user, isLoading: isUserLoading } = useGetUser();
  const {
    data: eventRoom,
    isFetching: isEventRoomLoading,
    isRoomNotExist,
  } = useGetEventRoom(user?.togetherEvent?.eventRoom.id);

  const router = useRouter();

  const {
    isLoading: isPageViewLoading,
    data: pvData,
    refetch,
  } = useQuery({
    queryKey: [WEB_QUERY_KEY.LAST_PAGE_VIEW, user],
    queryFn: async ({ queryKey }) => {
      const [, user] = queryKey as [string, User | undefined];

      try {
        // 우선 순위에 따라 id를 결정합니다.
        const condition = user
          ? where('user.uid', '==', user.uid)
          : !isEmpty(window.deviceToken)
          ? where('device_token', '==', window.deviceToken)
          : where('session_id', '==', sessionId());

        const q = query(
          collection(db, 'PageView'),
          where('eventName', '==', 'view_item'),
          condition,
          orderBy('date', 'desc'),
          limit(1),
        );

        const snap = await getDocs(q);

        if (snap.empty) return null;

        return snap.docs[0].data() as PageView;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    gcTime: 0,
    staleTime: 0,
  });

  useEffect(() => {
    if (router.pathname === '/') {
      refetch();
    }
  }, [router.pathname]);

  const keyConditions = useMemo(
    (): KeyCondition[] => [
      {
        key: 'togetherRoom',
        conditions: [Boolean(user && user.togetherEvent), !isRoomNotExist, !eventRoom?.isExpired],
        availablePaths: ['/'],
        expiredOption: {
          maintainOnSession: true,
          showAfterExpired: true,
        },
      },
      {
        key: 'lastPageView',
        conditions: [
          Boolean(pvData),
          // user?.orders.filter(
          //   (o) => o.state === 'Appointed' || o.state === 'Arrival' || o.state === 'Request',
          // )?.length === 0,
        ],
        availablePaths: ['/'],
        expiredOption: {
          maintainOnSession: true,
          showAfterExpired: true,
        },
      },
    ],
    [user, eventRoom, isRoomNotExist, pvData],
  );

  // 조건 등을 담은 hook을 여기서 선언.
  const {
    visibleStates: [isTogetherRoomVisible, isReturnBannerVisible],
    close,
    setSavedStateWithKey,
  } = useHandleGlobalVisible({
    isLoading: isUserLoading || isEventRoomLoading || isPageViewLoading,
    keyConditions,
  });

  return (
    <>
      <AnimatePresence>
        {isTogetherRoomVisible ? (
          <motion.main
            className={cn(
              'py-18 fixed z-50 flex w-full cursor-pointer justify-between bg-[rgba(0,0,0,0.85)] px-16',
            )}
            style={{ bottom: !isAppApproach ? 82 : 0 }}
            initial={{ y: 200 }}
            animate={{ y: 0 }}
            exit={{ y: 200 }}
            transition={{ type: 'spring', bounce: 0 }}
          >
            {/* 우선순위는 다음과 같이 정의*/}
            <div className="w-full">
              <TogetherRoomBottomFloatingBar close={close} />
            </div>
          </motion.main>
        ) : pvData && pvData.academy_name && isReturnBannerVisible ? (
          <ReservationReturnBanner
            pvData={pvData}
            close={() => {
              setSavedStateWithKey('lastPageView', { hideDate: TOMORROW });
              close();
            }}
          />
        ) : null}
      </AnimatePresence>
    </>
  );
};
