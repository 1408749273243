import { db } from 'firebase-config';
import { doc, getDoc } from 'firebase/firestore';
import { findMethodAndReplace } from 'shared-values';

import { Timetable } from '../models';

export const getTimetableData = async (id: string) => {
  const docRef = doc(db, 'Timetable', id);
  const docSnap = await getDoc(docRef);
  if (!docSnap.exists()) throw new Error('Document does not exist');

  const docData = { id, ...docSnap.data() };

  findMethodAndReplace(docData, 'toDate');
  return docData as TimetableData;
};

export const getTimetable = async ({ queryKey, queryClient }: QueryFn) => {
  const [, id] = queryKey as [string, string];
  const docData = await getTimetableData(id);
  return new Timetable({ ...docData, queryClient, queryKey });
};
