import { getWebLocation } from './get-web-location';

export const getWebLocationWhenHasPermission = async (): Promise<{
  location: LocationType | null;
  locationPermission: LocationPermission;
}> => {
  let state: PermissionState = 'prompt';

  if (navigator.permissions && navigator.permissions.query) {
    const { state: permissionState } = await navigator.permissions.query({ name: 'geolocation' });
    state = permissionState;
  }

  if (state === 'prompt')
    return {
      location: null,
      locationPermission: 'prompt',
    };

  return await getWebLocation();
};
