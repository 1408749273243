import { isValidElement, useCallback } from 'react';

import { AppBarItem, NewIcon } from 'design-system';
import { useRouter } from 'next/router';

import { BackLink } from './BackLink';

export const GlobalAppBarItem = ({
  item,
  backRedirectUrl,
}: {
  item?: GlobalAppBarItem;
  backRedirectUrl?: string;
}) => {
  const router = useRouter();

  const renderItem = useCallback(
    (item: GlobalAppBarItem) => {
      switch (item) {
        case 'Back':
          return (
            <BackLink backRedirectUrl={backRedirectUrl}>
              <AppBarItem
                item={<NewIcon icon="arrowline-outlined" size={24} className="fill-new-gray-900" />}
              />
            </BackLink>
          );
        case 'WhiteBack':
          return (
            <BackLink backRedirectUrl={backRedirectUrl}>
              <AppBarItem
                item={<NewIcon icon="arrowline-outlined" size={24} className="fill-new-white" />}
              />
            </BackLink>
          );
        case 'Close':
          return (
            <BackLink backRedirectUrl={backRedirectUrl}>
              <NewIcon icon="x-outlined" size={24} className="fill-new-gray-900" />
            </BackLink>
          );
        case 'ProfileSetting':
          return (
            <AppBarItem
              item={<NewIcon icon="gear-outlined" size={24} className="fill-new-gray-900" />}
              onClick={() => {
                router.push('/profile/setting');
              }}
            />
          );
        case 'ProfileNotification':
          return (
            <AppBarItem
              className="show-on-webview"
              item={<NewIcon icon="bell-outlined" size={24} className="fill-new-gray-900" />}
              onClick={() => {
                router.push('/profile/notification');
              }}
            />
          );
        case 'Coupon':
          return (
            <AppBarItem
              item={<NewIcon icon="coupon-outlined" size={24} className="fill-new-gray-900" />}
              onClick={() => {
                router.push('/profile/storage/coupon');
              }}
            />
          );
        case 'TextLogo':
          return (
            <AppBarItem
              item={<NewIcon icon="text-logo" width={67} height={18} />}
              onClick={() => {
                router.push('/');
              }}
            />
          );
        case 'Home':
          return (
            <AppBarItem
              item={<NewIcon icon="home-outlined" size={24} className="fill-new-gray-900" />}
              onClick={() => {
                router.push('/');
              }}
            />
          );
        default:
          return <AppBarItem item={<div />} />;
      }
    },
    [router, backRedirectUrl],
  );

  if (!item) return <AppBarItem item={<div />} />;

  if (isValidElement(item)) return <AppBarItem item={item} />;

  return <div className="p-6">{renderItem(item)}</div>;
};
