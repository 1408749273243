import { ReactNode } from 'react';

import classNames from 'classnames';
import { Portal } from 'design-system';

import { useWindowSize } from '@hooks';

interface PortalProps {
  children: ReactNode;
  onClickBackground?: () => void;
  type?: 'small' | 'big';
  className?: string;
}

export const Modal = ({ type = 'small', children, onClickBackground, className }: PortalProps) => {
  const { width } = useWindowSize();

  return (
    <Portal onClickBackground={onClickBackground}>
      <div
        className={classNames(
          'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-md bg-white px-12 pb-16 pt-21',
          className,
        )}
        style={
          type === 'small'
            ? {
                width: width > 500 ? 344 : width - 156,
              }
            : {
                width: width > 500 ? 430 : width - 70,
              }
        }>
        {children}
      </div>
    </Portal>
  );
};
