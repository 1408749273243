import { useGetUser } from '@db/collections';
import { Dialog, DialogButton } from 'design-system';
import { useRouter } from 'next/router';

export const ReviewRequestModal = ({ close }: { close: () => void }) => {
  const router = useRouter();
  const { user } = useGetUser();

  const lastestOrder = user && user.orders.find((order) => order.reviewState === 'can-write');

  if (!lastestOrder) return null;

  return (
    <Dialog
      close={close}
      image={{
        alt: '예약한 학원은 어떠셨나요?',
        src: '/review/image-present.png',
      }}
      title={'예약한 학원은 어떠셨나요?'}
      body={'다른 분들을 위해 리뷰를 작성해주시면,\n커피 기프티콘을 드려요!'}
      buttons={[
        <DialogButton
          buttonType='text'
          text='나중에 쓰기'
          key='나중에 쓰기'
          color='gray-900'
          onClick={close}
        />,
        <DialogButton
          buttonType='text'
          text='리뷰 쓰기'
          key='리뷰 쓰기'
          color='DTPurple-500'
          onClick={() => {
            router.push(`/profile/review?orderId=${lastestOrder.id}`);
          }}
        />,
      ]}
    />
  );
};
