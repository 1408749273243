import { useCallback, useEffect } from 'react';

import { once } from 'events';

import { userDefaultLocation } from '@constants';
import { useEmitContext } from '@contexts';

const EmitKey: {
  login: {
    phoneNum: string;
    notificationAgreed: boolean;
  };
  funnelScreensChange: {
    finish: boolean;
  };
  selectAcademy: {
    selectedAcademy: DisplayAcademy | null;
  };
  appLocation: {
    location: LocationType;
    locationPermission: Exclude<LocationPermission, 'prompt'>;
  };
} = {
  // key : value 구조
  login: {
    phoneNum: '',
    notificationAgreed: false,
  },
  funnelScreensChange: {
    finish: false,
  },
  selectAcademy: {
    selectedAcademy: null,
  },
  appLocation: {
    location: userDefaultLocation,
    locationPermission: '',
  },
};

type EmitKeyType = Exclude<keyof typeof EmitKey, number>;

export type EmitReturn<T> = T & {
  result: 'fail' | 'success';
};

const emitKey = <T extends EmitKeyType>(key: T): (typeof EmitKey)[T] => {
  return EmitKey[key];
};

export const useEmit = <T extends EmitKeyType>(key: T) => {
  const emitter = useEmitContext();

  const defaultValue = emitKey(key);

  useEffect(() => {
    return () => {
      emitter.removeAllListeners();
    };
  }, []);

  const triggerEmit = useCallback(async () => {
    const [asyncValue] = await once(emitter, key);

    return (asyncValue ?? { result: 'fail', ...defaultValue }) as EmitReturn<typeof defaultValue>;
  }, [defaultValue, emitter, key]);

  const emitWithValue = useCallback(
    (value: EmitReturn<typeof defaultValue>) => {
      emitter.emit(key, value);
    },
    [emitter, key],
  );

  return {
    emitWithValue,
    triggerEmit,
  };
};
