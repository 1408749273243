import { BOXBUTTON_COLOR, BoxButton, NewIcon } from 'design-system';
import Image from 'next/image';
import { useRouter } from 'next/router';

import { Modal } from '@components/Modal';

export const SimulationModal = ({
  close,
  onTodayNoShowClick,
}: {
  close: () => void;
  onTodayNoShowClick: () => void;
}) => {
  const router = useRouter();

  return (
    <Modal type="big" onClickBackground={close} className="!rounded-[24px]">
      <div className="absolute right-16 top-16 cursor-pointer" onClick={close}>
        <NewIcon icon="x-outlined" size={20} className="fill-new-gray-400" />
      </div>
      <div className="mt-27 flex flex-col items-center">
        <div className="text-Body3 px-15 text-DTBlue-400 rounded-[16px] bg-[rgba(78,125,255,0.12)] py-4">
          실내연습장 예약 오픈!
        </div>
        <div className="mt-12 text-center text-[20px] font-bold">
          면허 취득 비용이
          <br />
          너무 비싼 것 같다면?
        </div>
        <Image
          src="/simulation-modal.png"
          width={498}
          height={364}
          alt="시뮬레이션 모달"
          className="mt-12"
        />
        <BoxButton
          className="mt-16"
          text="자세히 보기"
          color={BOXBUTTON_COLOR.DTYellow}
          onClick={() => {
            router.push('/why-simulation');
            close();
          }}
        />
      </div>
      <div
        className="text-Line absolute-justify-center -bottom-37 absolute text-white"
        onClick={onTodayNoShowClick}
      >
        오늘 하루 안 보기
      </div>
    </Modal>
  );
};
