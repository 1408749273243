import * as React from 'react';

import { v4 as uuidv4 } from 'uuid';

import { NewIcon, NewIconType } from '../../NewIcon';

type ExcludeString<T> = T extends string ? never : T;

// ReactNode에서 string을 제외한 타입
type NonStringReactNode = ExcludeString<React.ReactNode>;

// NewIconType과 NonStringReactNode를 결합한 새로운 타입
type AppBarItemType = NewIconType | NonStringReactNode;

interface AppBarItemProps extends React.HTMLAttributes<HTMLButtonElement> {
  item: AppBarItemType;
}

export const AppBarItem = ({ item, ...props }: AppBarItemProps) => {
  if (typeof item === 'string') {
    return (
      <button {...props}>
        <NewIcon icon={item as NewIconType} />
      </button>
    );
  }

  return (
    <button {...props} key={uuidv4()}>
      {item}
    </button>
  );
};

// rightItem(s)를 렌더링하기 위한 함수
export const renderAppBarItems = (
  rightItem: React.ReactElement<typeof AppBarItem> | React.ReactElement<typeof AppBarItem>[],
) => {
  // rightItem이 배열인 경우, 배열의 각 요소를 렌더링
  if (Array.isArray(rightItem)) {
    return rightItem.map((item, index) => <React.Fragment key={item.key}>{item}</React.Fragment>);
  }
  // rightItem이 단일 요소인 경우, 해당 요소를 직접 렌더링
  return <React.Fragment>{rightItem}</React.Fragment>;
};
