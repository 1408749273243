import { useEffect, useState } from 'react';

import { LogEvent } from '@analytics';
import PromotionModal from '@ds/components/new/PromotionModal/PromotionModal';
import { useRouter } from 'next/router';

import { isAppApproaching } from '@utils/Common';

export const AppDownloadBottomSheet = ({
  isOpen,
  close,
  onTodayNoShowClick,
  onClickDownload,
  position,
}: {
  isOpen: boolean;
  close: () => void;
  onTodayNoShowClick: () => void;
  onClickDownload?: () => void;
  position: 'home_initial_modal' | 'test_after_two_problem' | 'blog_notification';
}) => {
  const isAppApproach = isAppApproaching();
  const router = useRouter();

  useEffect(() => {
    if (!isOpen) return;

    LogEvent.유입.webToApp(position);
  }, [isOpen]);

  const [isDownloadButtonClicked, setIsDownloadButtonClicked] = useState(false);

  if (isAppApproach) return null;

  return (
    <PromotionModal
      isOpen={isOpen}
      close={() => close()}
      promotion={{
        imageUrl: `/web_to_app_${
          position === 'home_initial_modal' ? 'home' : position === 'test_after_two_problem' ? 'test' : 'home'

        }.png`,
        onClick: () => {
          if (onClickDownload && !isDownloadButtonClicked) {
            onClickDownload();
            setIsDownloadButtonClicked(true);
          }
          router.push(
            `https://abr.ge/@drivingteacher/drivingteacher?campaign=${position}&og_tag_id=78371561&routing_short_id=poomda&sub_id=web_to_app&tracking_template_id=9680fedbf30414f7f003ba688aebc1ab&ad_type=click`,
          );
        },
      }}
      onTodayNoShowClick={() => {
        onTodayNoShowClick();
        close();
      }}
    />
  );
};
