import { useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { isScriptReadyState, isWebviewReadyState } from '@recoilState';
import { useQuery } from '@tanstack/react-query';

import { isAppApproaching } from '@utils/Common';

import { useEmit } from '../use-emit';
import { WEB_QUERY_KEY } from './../../constants/queryKey';
import { useReceiveAppLocation } from './hooks';
import { getWebLocation, getWebLocationWhenHasPermission } from './utils';

type WithoutRequesting = {
  myLocation: LocationType | null;
  locationPermission: LocationPermission;
};

type WhileRequesting = {
  myLocation: LocationType;
  locationPermission: Exclude<LocationPermission, 'prompt'>;
};

type GetLocationResult<IsSilent extends boolean> = IsSilent extends true
  ? WithoutRequesting
  : WhileRequesting;

export const useGetLocation = <IsSilent extends boolean = false>({
  isSilent = false as IsSilent,
} = {}) => {
  const isWebviewReady = useRecoilValue(isWebviewReadyState);
  const isScriptReady = useRecoilValue(isScriptReadyState);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [WEB_QUERY_KEY.MY_LOCATION, isSilent],
    queryFn: async () => {
      if (isSilent) {
        return getLocationWithoutRequestingPermission();
      } else {
        return getLocationWhileRequestingPermission();
      }
    },
    enabled: isScriptReady && isWebviewReady,
  });
  const [isFunctionLoading, setIsFunctionLoading] = useState(false);

  /**
   * 타입 정의를 위헤 emitKey를 분리.
   */
  const { triggerEmit } = useEmit('appLocation');

  useReceiveAppLocation();

  const isAppApproach = isAppApproaching();

  /**
   * isSilent가 true라면 권한을 요청하지 않음.
   * location은 null일 수 있고, locationPermission이 prompted 일 수 있다. (이전에 권한을 허용한 경우에만 가져옴.)
   */
  const getLocationWithoutRequestingPermission =
    useCallback(async (): Promise<WithoutRequesting> => {
      if (isAppApproach) {
        window.flutter_inappwebview.callHandler('SendLocationSilently');

        const { result, location, locationPermission } = (await triggerEmit()) as {
          result: 'success' | 'fail';
          location: null | LocationType;
          locationPermission: LocationPermission;
        };

        if (result !== 'success') throw new Error('유효한 값이 아닙니다.');
        return {
          myLocation: location,
          locationPermission,
        };
      } else {
        const { location, locationPermission } = await getWebLocationWhenHasPermission();

        return {
          myLocation: location,
          locationPermission,
        };
      }
    }, [isAppApproach, triggerEmit]);

  /**
   * isSilent가 false라면 권한을 요청하게 되며, locationPermission이 prompted 일 수 없다.
   * location은 null일 수 없고 (기본 위치라도 무조건 가져옴), locationPermission은 granted나 denied이다.
   */
  const getLocationWhileRequestingPermission = useCallback(async (): Promise<WhileRequesting> => {
    if (isAppApproach) {
      window.flutter_inappwebview.callHandler('SendLocation');
      const { result, location, locationPermission } = await triggerEmit();
      if (result !== 'success' || !location) throw new Error('유효한 값이 아닙니다.');
      return {
        myLocation: location,
        locationPermission,
      };
    } else {
      const { location, locationPermission } = await getWebLocation();
      return {
        myLocation: location,
        locationPermission,
      };
    }
  }, [isAppApproach, triggerEmit]);

  return {
    ...({
      myLocation: data ? data.myLocation : isSilent ? null : undefined,
      locationPermission: data ? data.locationPermission : '',
    } as GetLocationResult<typeof isSilent>),
    getLocationWhileRequestingPermission: async () => {
      setIsFunctionLoading(true);
      const result = await getLocationWhileRequestingPermission();
      setIsFunctionLoading(false);
      return result;
    },
    isLoading: isFetching,
    isFunctionLoading,
  };
};
