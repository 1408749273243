import { BaseModel } from '@db/base-models';
import { LESSON_CONTENT, LICENSE_CONTENT, LessonToLicenseMap } from 'shared-values';

export class BaseLessonCode extends BaseModel {
  code: LessonCode;

  constructor(code: LessonCode) {
    super('BaseLessonCode');
    this.code = code;
  }

  get licenseCode() {
    return LessonToLicenseMap[this.code];
  }
  get license() {
    return LICENSE_CONTENT[this.licenseCode] ? LICENSE_CONTENT[this.licenseCode].title : '';
  }
  get titleWithEmoji() {
    return LICENSE_CONTENT[this.licenseCode]
      ? LICENSE_CONTENT[this.licenseCode].titleWithEmoji
      : '';
  }
  get variant() {
    return LESSON_CONTENT[this.code] ? LESSON_CONTENT[this.code].display.sub : '';
  }
  // license + variant를 조합한 label을 반환
  get label() {
    const variantString = this.variant ? `(${this.variant})` : '';
    return this.license + variantString;
  }
  public getLessonContent(): LessonContentProperty {
    return LESSON_CONTENT[this.code];
  }
  public getLicenseContent(): LicenseContentProperty {
    return LICENSE_CONTENT[this.licenseCode];
  }
}
