import { atom } from 'recoil';

import { localStorageEffect } from '@utils/Common';

export const gcooEventParticiatedState = atom<{
  participated: boolean;
  maintainDate: Date;
}>({
  key: 'gcooEventParticiatedState',
  default: {
    participated: false,
    maintainDate: new Date(),
  },
  effects: [localStorageEffect('gcooEventParticiatedState')],
});
