import { PropsWithChildren } from 'react';

import Head from 'next/head';
import { usePathname } from 'next/navigation';

interface Props {
  isRobotAllowed?: boolean; // search engine robots
}
const ogUrl = (pathname: string) => {
  if (pathname === '/') {
    return 'https://drivingteacher.co.kr';
  }

  return `https://drivingteacher.co.kr${pathname}`;
};

export const CommonHead = ({ children }: PropsWithChildren<Props>) => {
  const pathname = usePathname();
  return (
    <Head>
      <meta charSet='utf-8' />
      <meta httpEquiv='x-ua-compatible' content='ie=edge' />
      <meta
        key='viewport'
        name='viewport'
        content='width=device-width, initial-scale=1.0, maximum-scale=5.0, user-scalable=no'
      />
      <meta name='googlebot' content='All' />
      <meta name='googlebot' content='index,follow' />
      <meta name='NaverBot' content='All' />
      <meta name='NaverBot' content='index,follow' />
      <meta name='robots' content='index,follow' key='robots' />
      <meta name='Yeti' content='All' />
      <meta name='Yeti' content='index,follow' />
      <meta name='google-site-verification' content='nzdbm0Hstd-jNiIf34zySB6N3jfwKaNlwT-y8xvPlp4' />
      <meta name='naver-site-verification' content='a9368a5ac0de7408bdddc4b975f294b47b5c3880' />
      <meta name='author' content='운전선생' />
      <meta property='og:type' content='website' />
      <meta property='og:site_name' content='운전선생' />
      <meta property='og:locale' content='ko_KR' />
      <meta property='og:image:width' content='1347' />
      <meta property='og:image:height' content='708' />
      <meta
        key='meta-og-image'
        property='og:image'
        content={
          'https://firebasestorage.googleapis.com/v0/b/drivingteacher-eeb82.appspot.com/o/ogImage.png?alt=media&token=5fc2835c-e435-49b4-a851-1d97a3a85ce4'
        }
      />
      <meta key='meta-og-url' property='og:url' content={ogUrl(pathname)} />
      <link rel='canonical' key='canonical' href={ogUrl(pathname)} />
      <title key='title'>운전면허 학원 최저가 예약 | 운전선생</title>
      <meta name='title' key='meta-title' content='운전면허 학원 최저가 예약 | 운전선생' />
      <meta
        property='og:title'
        key='meta-og-title'
        content='운전면허 학원 최저가 예약 | 운전선생'
      />
      <meta
        key='meta-description'
        name='description'
        content='가장 저렴하고 가까운 운전면허 학원을 예약해 보세요. 1종 보통, 2종 자동의 가격 정보, 수업 정보, 필기 공부 등 운전면허 취득에 필요한 정보를 확인하세요.'
      />
      <meta
        key='meta-og-description'
        property='og:description'
        content='가장 저렴하고 가까운 운전면허 학원을 예약해 보세요. 1종 보통, 2종 자동의 가격 정보, 수업 정보, 필기 공부 등 운전면허 취득에 필요한 정보를 확인하세요.'
      />
      <meta
        key='meta-keywords'
        name='keywords'
        content='운전면허,운전면허필기,운전면허 필기시험,운전면허시험장,운전연수,초보운전연수,개인운전연수,방문운전연수,방문주행연수,운전선생'
      />
      <meta name='apple-itunes-app' content='app-id=6444027313' />
      {children}
    </Head>
  );
};
