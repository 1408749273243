import { 운전선생허용수업 } from '../../constants';

// LessonCode를 LicenseCode로 매핑하는 객체
export const LessonToLicenseMap: Record<LessonCode, LicenseCode> = {
  ONE_NORMAL: 'ONE_NORMAL',
  ONE_CANCEL: 'ONE_NORMAL',
  TWO_NORMAL: 'TWO_NORMAL',
  TWO_NORMAL_CAMP: 'TWO_NORMAL',
  TWO_NORMAL_VISIT_TRAINING_8_HOURS: 'VISIT_TRAINING',
  TWO_CANCEL: 'TWO_NORMAL',
  ONE_NORMAL_ROAD: 'ONE_NORMAL',
  TWO_NORMAL_ROAD: 'TWO_NORMAL',
  TRANSFORM_TWO_TO_ONE: 'TRANSFORM_TWO_TO_ONE',
  ONE_LARGE: 'ONE_LARGE',
  TWO_SMALL_HAVE_LICENSE: 'TWO_SMALL',
  TWO_SMALL_NEW: 'TWO_SMALL',
  TWO_SMALL_HAVE_MOTOR: 'TWO_SMALL',
  SCOOTER: 'SCOOTER',
  SMALL_TRAILER: 'SMALL_TRAILER',
  LARGE_TRAILER: 'LARGE_TRAILER',
  TRACTION: 'LARGE_TRAILER',
  TRAINING_4_HOURS: 'TRAINING',
  TRAINING_6_HOURS: 'TRAINING',
  TRAINING_10_HOURS: 'TRAINING',
};

export const lessonMapping = Object.entries(LessonToLicenseMap).reduce((acc, [lesson, license]) => {
  const isNotAllowed = !운전선생허용수업.includes(lesson as LessonCode);
  if (isNotAllowed) {
    return acc;
  }

  acc[license] = acc[license] || [];
  acc[license].push(lesson as LessonCode);
  return acc;
}, {} as Record<LicenseCode, LessonCode[]>);

// 오직 여러 LessonCode가 매핑된 LicenseCode만 반환
const multipleLessonMapping = Object.entries(lessonMapping).reduce((acc, [license, lessons]) => {
  if (lessons.length > 1) {
    acc[license as LicenseCode] = lessons;
  }
  return acc;
}, {} as Record<LicenseCode, LessonCode[]>);

// 각 LicenseCode에 대해 여러 LessonCode가 매핑될 수 있는 경우를 찾는 함수
export const findMultiLessonsOnLicense = (licenseCode: LicenseCode) => {
  return multipleLessonMapping[licenseCode];
};
