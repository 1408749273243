import { getDiscountPrice } from '@db/base-models';
import { DisplayAcademy } from '@models';
import { distanceBetween } from 'geofire-common';

import { ExploreMapAcademy } from '@templates/Explore/Explore/hooks/use-get-explore-academies/use-get-explore-academies';

import { userDefaultLocation } from '@constants';

export const getDistanceFrom = (
  myLocation?: LocationType | null,
  targetLocation?: LocationType | null,
) => {
  if (!myLocation || !targetLocation) return 'undefined';

  if (
    myLocation.latitude === userDefaultLocation.latitude &&
    myLocation.longitude === userDefaultLocation.longitude
  )
    return 'default-location';

  const distanceInKm = distanceBetween(
    [myLocation.latitude, myLocation.longitude],
    [targetLocation.latitude, targetLocation.longitude],
  );
  const distanceInM = distanceInKm * 1000;

  if (distanceInM < 100) return '100m';
  if (distanceInM < 500) return '500m';
  if (distanceInM < 1000) return '1km';
  if (distanceInM < 2000) return '2km';
  if (distanceInM < 3000) return '3km';
  if (distanceInM < 5000) return '5km';
  if (distanceInM < 10000) return '10km';
  if (distanceInM < 30000) return '30km';
  if (distanceInM < 100000) return '100km';
  if (distanceInM < 300000) return '300km';
  if (distanceInM < 1000000) return 'infinite';

  return 'undefined';
};

const getReviewScore = (score: number) => {
  if (score < 0.5) return '0';
  if (score < 1) return '0.5';
  if (score < 1.5) return '1';
  if (score < 2) return '1.5';
  if (score < 2.5) return '2';
  if (score < 3) return '2.5';
  if (score < 3.5) return '3';
  if (score < 4) return '3.5';
  if (score < 4.5) return '4';
  if (score < 5) return '4.5';
  return '5';
};

/**
 * 공통으로 사용되는 parameter들을 모아둔 객체
 */
const CommonParams = {
  getAcademyParams(
    academy?:
      | DisplayAcademy
      | Academy
      | ExploreMapAcademy
      // pv_together_room 때문에 최소의 Academy의 속성을 정의함
      // 만약 확장해야 한다면 eventRoom의 eventAcademy의 속성을 확장해야 함
      | {
          id: string;
          locationAttachedName: string;
          type: string;
          state: Academy['state'];
          totalReviewCount?: number;
          averageRate?: Academy['averageRate'];
        },
  ) {
    if (!academy) return {};

    return {
      academy_id: academy.id,
      academy_name: academy.locationAttachedName,
      academy_type: academy.type,
      academy_state: academy.state,
      academy_review_count: academy.totalReviewCount ?? 'undefined',
      academy_review_score: academy.averageRate
        ? getReviewScore(academy.averageRate.totalAverage)
        : 'undefined',
      ...(academy instanceof DisplayAcademy ? { lesson_name: academy.lesson.label } : {}),
    };
  },
  getLessonParams(lesson: Lesson) {
    return {
      lesson_id: lesson.code,
      lesson_name: lesson.label,
      lesson_category: lesson.isTraining ? '연수' : '면허',
      lesson_price: lesson.price,
    };
  },
  getDistanceParams(myLocation?: LocationType | null, targetLocation?: LocationType | null) {
    return {
      distance_from: getDistanceFrom(myLocation, targetLocation),
    };
  },
};

/**
 * Ecommer에서 자주 쓰이는 parameter들을 모아둔 객체.
 * 각 단계 별 상품(속성) 정의는 다음 문서를 참고.
 * https://www.notion.so/f8727fc637794a67931b18b636161b8d?pvs=4
 */
const EcommerceParams = {
  getGAAcademyParams(
    academies:
      | (DisplayAcademy | Academy | ExploreMapAcademy)[]
      | DisplayAcademy
      | Academy
      | ExploreMapAcademy,
    myLocation?: LocationType | null,
    applicationMode?: string,
  ) {
    return {
      item_list_id: 'list-view',
      item_list_name: '상품 목록 조회',
      application_mode: applicationMode ?? 'none',

      items: Array.isArray(academies)
        ? academies.map((academy) => {
            const { id, locationAttachedName, location, type, state } = academy;
            const [도, 시] = location.jibunAddress.split(' ');

            return {
              item_id: id,
              item_name: locationAttachedName,
              item_category: type,
              item_category2: `${도} ${시}`,
              item_category3: state,
              item_category4: academy.totalReviewCount ?? 'undefined',
              application_mode: applicationMode ?? 'none',
              academy_review_score: academy.averageRate
                ? getReviewScore(academy.averageRate.totalAverage)
                : 'undefined',
              price: 0,
              distance_from: getDistanceFrom(myLocation, {
                latitude: location.latitude,
                longitude: location.longitude,
              }),
            };
          })
        : [
            {
              item_id: academies.id,
              item_name: academies.locationAttachedName,
              item_category: academies.type,
              item_category2: `${academies.location.jibunAddress.split(' ')[0]} ${
                academies.location.jibunAddress.split(' ')[1]
              }`,
              item_category3: academies.state,
              item_category4: academies.totalReviewCount ?? 'undefined',
              application_mode: applicationMode ?? 'none',
              academy_review_score: academies.averageRate
                ? getReviewScore(academies.averageRate.totalAverage)
                : 'undefined',
              price: 0,
              distance_from: getDistanceFrom(myLocation, {
                latitude: academies.location.latitude,
                longitude: academies.location.longitude,
              }),
            },
          ],
    };
  },

  getAirbridgeAcademyParams(
    academies:
      | (DisplayAcademy | Academy | ExploreMapAcademy)[]
      | DisplayAcademy
      | Academy
      | ExploreMapAcademy,
    applicationMode?: string,
  ) {
    const semanticAttributes = {
      productListID: 'list-view',
      applicationMode: applicationMode ?? 'none',
      products: Array.isArray(academies)
        ? academies.map((academy) => {
            const [도, 시] = academy.location.jibunAddress.split(' ');

            return {
              productID: academy.id,
              productName: academy.locationAttachedName,
              location: `${도} ${시}`,
              type: academy.type,
              applicationMode: applicationMode ?? 'none',
            };
          })
        : [
            {
              productID: academies.id,
              productName: academies.name,
              location: `${academies.location.jibunAddress.split(' ')[0]} ${
                academies.location.jibunAddress.split(' ')[1]
              }`,
              type: academies.type,
              applicationMode: applicationMode ?? 'none',
            },
          ],
    };

    return {
      action: applicationMode,
      ...(!Array.isArray(academies) && {
        label: academies.locationAttachedName,
      }),
      ...semanticAttributes,
      semanticAttributes,
    };
  },

  getGALessonParams(
    academy: Academy,
    lesson: Lesson,
    applicationMode: string,
    myLocation?: LocationType | null,
  ) {
    const [도, 시] = academy.location.jibunAddress.split(' ');

    return {
      item_list_id: academy.id,
      item_list_name: academy.locationAttachedName,
      application_mode: applicationMode ?? 'none',
      lesson_id: lesson.code,
      lesson_name: lesson.label,
      items: [
        {
          item_id: lesson.code,
          item_name: lesson.label,
          item_category: lesson.isTraining ? '연수' : '면허',
          item_category2: academy.type,
          item_category3: `${도} ${시}`,
          item_category4: academy.state,
          item_category5: academy.totalReviewCount ?? 'undefined',
          price: lesson.price,
          application_mode: applicationMode ?? 'none',
          academy_review_score: academy.averageRate
            ? getReviewScore(academy.averageRate.totalAverage)
            : 'undefined',
          distance_from: getDistanceFrom(myLocation, {
            latitude: academy.location.latitude,
            longitude: academy.location.longitude,
          }),
        },
      ],
    };
  },

  getAirbridgeLessonParams(academy: Academy, lesson: Lesson, applicationMode: string) {
    const [도, 시] = academy.location.jibunAddress.split(' ');

    const semanticAttributes = {
      // 특정 이벤트와 상관 없이 들어갈 수 있는 이벤트의 parameter를 넣습니다.
      productListID: academy.id,
      cartID: academy.id,
      totalValue: lesson.price,
      currency: 'KRW',
      products: [
        {
          productID: lesson.code,
          quantity: 1,
          name: lesson.label,
          price: lesson.price,
          currency: 'KRW',
          location: `${도} ${시}`,
          type: academy.type,
          applicationMode: applicationMode ?? 'none',
        },
      ],
    };

    return {
      action: applicationMode,
      label: lesson.label,
      value: lesson.price,
      ...semanticAttributes,
      semanticAttributes,
    };
  },

  getGADiscountParams(price: number, discounts: Discount[]) {
    const totalPrice = getDiscountPrice({ price, discounts });

    if (discounts.find((discount) => discount.discountType === 'together')) {
      return {
        discount_type: 'together',
        discount_price: totalPrice.할인금 ?? 0,
      };
    }

    return {
      discount_type: 'coupon',
      discount_price: totalPrice.할인금 ?? 0,
      dt_coupon:
        discounts.find((discount) => discount.discountSource === 'dt')?.promotionName ?? '',
      simulation_coupon:
        discounts.find((discount) => discount.discountSource === 'simulation')?.promotionName ?? '',
    };
  },
};

export const AnalyticsParams = {
  ecommerce: EcommerceParams,
  common: CommonParams,
};
