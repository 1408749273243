import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';

import { globalVisibleState } from '@recoilState';
import { useRouter } from 'next/router';

const now = new Date();

// todo : 조건과 레이아웃을 분리하기
// 띄워줄 조건 등은 key에 따라 결정하는 것(즉, hook 내부에서)이 맞을 것 같고,
// 레이아웃은 hook을 사용할 때 결정하는 것이 맞을 것 가탇.

/**
 * # useHandleGlobalVisible
 * 
 * 전역적으로 보여지는 컴포넌트의 가시성을 관리하는 훅입니다.

 * @param {KeyCondition[]} keyConditions - 코치마크의 가시성을 결정하는 조건들입니다.
 * @param {boolean} isLoading - 로딩 상태입니다.
 */
export const useHandleGlobalVisible = ({
  keyConditions,
  isLoading = false,
}: {
  keyConditions: KeyCondition[];
  isLoading?: boolean;
}) => {
  const [savedState, setSavedState] = useRecoilState(globalVisibleState);
  const [visibleStates, setVisibleStates] = useState(keyConditions.map(() => false));
  const maintainOnSessionFlags = useRef(keyConditions.map(() => true));
  const router = useRouter();

  useEffect(() => {
    // reset visible states when route changes
    const handleRouteChange = () => {
      setVisibleStates(keyConditions.map(() => false));
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router]);

  const updateVisibility = (isVisible: boolean, index: number, maintainOnSession: boolean) => {
    if (isVisible && !maintainOnSession) {
      maintainOnSessionFlags.current[index] = false;
    }
    setVisibleStates((prev) => {
      const newState = [...prev];
      newState[index] = isVisible;
      return newState;
    });
  };

  const setSavedStateWithKey = useCallback(
    (key: string, value: { maintainDate?: Date | null; hideDate?: Date | null }) => {
      setSavedState((prev) => {
        const newState = { ...prev };
        newState[key] = {
          maintainDate: value.maintainDate ?? null,
          hideDate: value.hideDate ?? null,
        };
        return newState;
      });
    },
    [],
  );

  useEffect(() => {
    if (typeof window === 'undefined' || isLoading) return;
    keyConditions.forEach(
      (
        {
          key,
          conditions = [],
          availablePaths = [],
          expiredOption: {
            action,
            expiredDate,
            showAfterExpired = true,
            maintainOnSession = false,
          } = {
            showAfterExpired: true,
            maintainOnSession: false,
          },
        },
        index,
      ) => {
        const isVisible = visibleStates[index];

        if (
          isVisible ||
          (availablePaths.length > 0 && !availablePaths.includes(router.pathname)) ||
          !maintainOnSessionFlags.current[index]
        )
          return;

        const findSavedState = savedState[key];

        const { maintainDate: _maintainDate = null, hideDate: _hideDate = null } =
          findSavedState ?? {};

        const isInitialApproach = !findSavedState;

        if (!isInitialApproach) {
          // 유지 기간이면 보여준다.
          if (_maintainDate && now <= _maintainDate) {
            updateVisibility(true, index, maintainOnSession);
            return;
          }

          // 숨기기 기간이면 숨긴다.
          if (_hideDate && now <= _hideDate) {
            updateVisibility(false, index, maintainOnSession);
            return;
          }

          if (!showAfterExpired) {
            updateVisibility(false, index, maintainOnSession);
            return;
          }
        }

        if (conditions.length > 0 && conditions.every((condition) => condition)) {
          updateVisibility(true, index, maintainOnSession);
          setSavedStateWithKey(key, {
            maintainDate: action && action === 'maintain' ? expiredDate : null,
            hideDate: action && action === 'hide' ? expiredDate : null,
          });
          return;
        }
      },
    );
  }, [...keyConditions, isLoading, router.pathname]);

  return {
    visibleStates: visibleStates,
    isVisible: visibleStates.some((v) => v),
    close: () => setVisibleStates(keyConditions.map(() => false)),
    savedState,
    setSavedStateWithKey,
  };
};
