import { isEmptyString } from 'shared-values';

import { BaseModel, SelectedRange, SelectedTime } from '../../../base-models';

export const shuttleBusPreferenceToText = (shuttleBusPreference: ShuttleBusPreference) => {
  switch (shuttleBusPreference) {
    case 'yes':
      return '희망';
    case 'no':
      return '미희망';
    case 'unsure':
      return '미정';
  }
};

export class Receipt extends BaseModel {
  id: string;
  academyId: string;
  academySubCoNo: string;
  academySubMid: string;
  academySmallRank: string;
  academyLocation: string;
  academyName: string;
  academyPhoneNum: string;
  lessonCode: LessonCode;
  lessonPrice: number;
  lessonName: string;
  lessonPeriodText: string;
  lessonRegistType: string;
  deposit: number;
  selectedTimes: SelectedTime[];
  selectedRange: SelectedRange | null;
  userId: string;
  userName: string;
  userPhoneNum: string;
  userBirthday: string;
  userAddress: string;
  userDetailAddress: string;
  device?: Device;
  paymentType: PaymentType;
  isSimulation: boolean;
  image: string;
  applicationMode: string;
  shuttleBusPreference: ShuttleBusPreference;
  visitorName?: string;
  visitorPhoneNum?: string;
  visitorBirthday?: string;

  constructor({
    id,
    academyId,
    academySubCoNo,
    academySubMid,
    academySmallRank,
    academyLocation,
    academyName,
    academyPhoneNum,
    lessonCode,
    lessonPrice,
    lessonName,
    lessonPeriodText,
    lessonRegistType,
    deposit,
    selectedTimes,
    selectedRange,
    userId,
    userName,
    userPhoneNum,
    userBirthday,
    userAddress,
    userDetailAddress,
    device,
    paymentType,
    isSimulation,
    image,
    applicationMode,
    shuttleBusPreference,
    visitorName,
    visitorPhoneNum,
    visitorBirthday,
  }: ReceiptData) {
    super('Receipt');
    this.id = id;
    this.academyId = academyId;
    this.academySubCoNo = academySubCoNo;
    this.academySubMid = academySubMid;
    this.academySmallRank = academySmallRank;
    this.academyLocation = academyLocation;
    this.academyName = academyName;
    this.academyPhoneNum = academyPhoneNum;
    this.lessonCode = lessonCode;
    this.lessonPrice = lessonPrice;
    this.lessonName = lessonName;
    this.lessonPeriodText = lessonPeriodText ?? '';
    this.lessonRegistType = lessonRegistType ?? '';
    this.deposit = deposit;
    this.selectedTimes = selectedTimes.map((selectedTime) => new SelectedTime(selectedTime));
    this.selectedRange = selectedRange ? new SelectedRange(selectedRange) : null;
    this.userId = userId;
    this.userName = userName;
    this.userPhoneNum = userPhoneNum;
    this.userBirthday = userBirthday;
    this.userAddress = userAddress;
    this.userDetailAddress = userDetailAddress;
    this.device = device;
    this.paymentType = paymentType;
    this.isSimulation = isSimulation ?? false;
    this.image = image;
    this.applicationMode = applicationMode ?? '';
    this.shuttleBusPreference = shuttleBusPreference ?? 'unsure';
    this.visitorName = visitorName;
    this.visitorPhoneNum = visitorPhoneNum;
    this.visitorBirthday = visitorBirthday;
  }

  get shuttleBusPreferenceText() {
    return shuttleBusPreferenceToText(this.shuttleBusPreference);
  }

  get visitorNameText() {
    return isEmptyString(this.visitorName) ? this.userName : this.visitorName ?? '';
  }

  get isVisitTraining() {
    return this.lessonCode.includes('VISIT_TRAINING');
  }

  get visitorPhoneNumText() {
    return isEmptyString(this.visitorPhoneNum) ? this.userPhoneNum : this.visitorPhoneNum ?? '';
  }

  get visitorBirthdayText() {
    return isEmptyString(this.visitorBirthday) ? this.userBirthday : this.visitorBirthday ?? '';
  }
}
