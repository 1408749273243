import { useEffect, useState } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';

import { navbarPropsState } from '@recoilState';
import { useRouter } from 'next/router';

import { PAGE_MAPPING } from '@constants/page-mapping';

const DEFAULT_APPBAR_PROPS: GlobalAppBarProps = { offNavbar: true };

export const useGetAppBarProps = (): GlobalAppBarProps => {
  const router = useRouter();

  const findMapping = PAGE_MAPPING[router.pathname as keyof typeof PAGE_MAPPING]
    ? (PAGE_MAPPING[router.pathname as keyof typeof PAGE_MAPPING].globalAppBar as GlobalAppBarProps)
    : DEFAULT_APPBAR_PROPS;

  const [globalNavbarProps] = useRecoilState(navbarPropsState);
  const resetGlobalNavbarProps = useResetRecoilState(navbarPropsState);
  const [localNavbarProps, setLocalNavbarProps] = useState<GlobalAppBarProps>(findMapping);
  const resetLocalNavbarProps = () => setLocalNavbarProps(findMapping);

  useEffect(() => {
    setLocalNavbarProps(findMapping);

    return () => {
      resetGlobalNavbarProps();
      resetLocalNavbarProps();
    };
  }, [router.pathname]);

  return {
    ...localNavbarProps,
    // global을 우선 적용함.
    ...globalNavbarProps,
  };
};
