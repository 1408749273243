import { distanceBetween } from 'geofire-common';

import { QueryListModel } from '../../../query-models';
import { Academy } from './academy';

// 내 주변으로부터 50km 위치들 다 불러오기
const radiusInM = 50 * 1000;
export class AcademyList extends QueryListModel {
  items: Academy[];

  constructor({ items, queryKey, queryClient }: DataListModel<Academy>) {
    super({
      items: items,
      queryClient,
      queryKey,
      instanceConstructor: AcademyList,
      className: 'AcademyList',
    });
    this.items = items;
  }

  public setNearbyAcademies({
    targetLocation,
    myLocation,
  }: {
    targetLocation: LocationType;
    myLocation: LocationType;
  }) {
    this.items = this.getNearByAcademies({ targetLocation, myLocation });

    return this;
  }

  public getNearByAcademies({
    targetLocation,
    myLocation,
  }: {
    targetLocation: LocationType;
    myLocation: LocationType;
  }) {
    const { latitude, longitude } = targetLocation;

    const result = [];

    for (const academy of this.items) {
      const {
        location: { latitude: lat, longitude: lng },
      } = academy;

      const distanceInKm = distanceBetween([lat, lng], [latitude, longitude]);

      const distanceInM = distanceInKm * 1000;

      if (distanceInM <= radiusInM) {
        academy.setDisatnce(Number(distanceInKm.toFixed(2)));
        result.push(academy);
      }
    }

    return result;
  }

  public setDistanceFromMyLocation({
    latitude,
    longitude,
  }: {
    latitude: number;
    longitude: number;
  }) {
    this.items = this.items.map((v) => {
      const { latitude: lat, longitude: lng } = v.location;
      const distanceInKm = distanceBetween([lat, lng], [latitude, longitude]);

      v.setDisatnce(Number(distanceInKm.toFixed(2)));
      return v;
    });
    return this;
  }

  /**
   * distance가 set 되어 있어야 함.
   */
  public sortByDistance() {
    if (this.items.length > 0 && !this.items[0].distance)
      throw new Error('distance가 set 되어 있어야 함.');

    this.items = this.items.sort((a, b) => {
      return a.distance - b.distance;
    });

    return this;
  }

  public sortByPrice() {
    this.items = this.items.sort((a, b) => {
      const aPrice = a.selectLesson({ code: 'TWO_NORMAL' })?.price;
      const bPrice = b.selectLesson({ code: 'TWO_NORMAL' })?.price;

      return aPrice - bPrice;
    });

    return this;
  }

  public sortOnlyBookable() {
    this.items = this.items.filter((v) => v.isAcademyBookable);

    return this;
  }

  public slice(number = 0) {
    this.items = this.items.slice(0, number);

    return this;
  }

  public set수도권() {
    this.items = this.items.filter((v) => {
      return (
        v.location.exact?.includes('서울') ||
        v.location.exact?.includes('경기') ||
        v.location.exact?.includes('인천')
      );
    });

    return this;
  }
}
