import { QUERY_KEY } from '@db/constants';
import { Academy } from 'database';

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

function hasDisplayProperties(obj: any): obj is DisplayAcademy {
  if ('lesson' in obj && !('lessons' in obj) && !('academy' in obj)) return true;
  return false;
}

const simulation우선순위: {
  periodText: string;
  registType: RegistType;
}[] = [
  { periodText: '1개월', registType: 'license' },
  { periodText: '3개월', registType: 'license' },
  { periodText: '2개월', registType: 'license' },
  { periodText: '6개월', registType: 'license' },
  { periodText: '1개월', registType: 'training' },
  { periodText: '3개월', registType: 'training' },
  { periodText: '2개월', registType: 'training' },
  { periodText: '6개월', registType: 'training' },
];

/**
 *  DisplayAcademy는 Lesson이 확정된 Academy (Lessons가 없고, Lesson 필드가 존재.)
 *  LessonsConfirmedAcademy? 로 추후 이름 수정하기.
 */
export class DisplayAcademy extends Academy {
  lesson: Lesson;
  lessonCode: LessonCode;
  // DisplayAcademy를 Academy로 변환할 때 필요한 정보
  private _lessons: Lesson[];

  constructor({
    academy,
    lessonCode,
    queryClient,
    ...rest
  }: DataModel<{ academy?: Academy; lessonCode: LessonCode }> & Partial<DisplayAcademy>) {
    // 2가지의 적용 방식
    // 1. academy가 있으면 academy를 통해 생성 (Academy -> DisplayAcademy 변환)
    if (academy && !queryClient) {
      const { queryClient } = academy.getQueryVariables();
      super({
        ...academy,
        lessons: [],
        queryClient,
        instanceConstructor: DisplayAcademy,
        className: 'DisplayAcademy',
        topReviews: academy.topReviews.map((review) => review.get()) as ReviewData[],
      });

      let findLesson;
      for (let i = 0; i < simulation우선순위.length; i++) {
        try {
          findLesson = academy.selectLesson({
            code: lessonCode,
            ...(this.isSimulation
              ? {
                  periodText: simulation우선순위[i].periodText,
                  registType: simulation우선순위[i].registType,
                }
              : {}),
          });
          break; // 수업을 성공적으로 찾았으면 반복문 종료
        } catch (error) {
          if (i === simulation우선순위.length - 1) {
            // 마지막 시도에서도 실패했을 경우의 처리
            // 여기에 실패 처리 로직을 추가할 수 있습니다.
            console.error('모든 시도가 실패했습니다.', error);
          }
          // 실패할 경우 다음 기간으로 넘어감
        }
      }
      if (!findLesson) throw new Error('해당 수업이 없습니다.');
      this.lesson = findLesson;
      this.lessonCode = lessonCode;
      this._lessons = academy.lessons;
      delete (this as PartialBy<Academy, 'lessons'>).lessons;
    }
    // 2. academy가 없으면, 그대로 생성 (DisplayAcademy -> DisplayAcademy 변환)
    else if (hasDisplayProperties(rest) && queryClient) {
      super({
        ...rest,
        lessons: [],
        queryClient,
        topReviews: rest.topReviews.map((review) => review.get()) as ReviewData[],
        instanceConstructor: DisplayAcademy,
        className: 'DisplayAcademy',
      });
      this.lesson = rest.lesson;
      this.lessonCode = lessonCode;
      this._lessons = rest._lessons;
    } else {
      //장기간 화면 방치 시 에러 화면으로 넘어가는 이슈
      // window.location.href = 'https://drivingteacher.co.kr/explore';
      throw new Error('academy 또는 displayAcademy가 필요합니다');
    }
  }

  public convertToAcademy() {
    const { queryClient } = this.getQueryVariables();
    const academy = new Academy({
      ...this,
      lessons: this._lessons,
      queryKey: [QUERY_KEY.ACADEMY, this.id],
      queryClient,
      instanceConstructor: Academy,
      className: 'Academy',
      topReviews: this.topReviews.map((review) => review.get()) as ReviewData[],
    });
    return academy;
  }
}
